import { type FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { FormattedMessage } from 'dibs-react-intl';
import { pageTypeConstants } from '../../constants/pageTypeConstants';

import { type SbMobileDesignsDescription_itemSearch$data } from './__generated__/SbMobileDesignsDescription_itemSearch.graphql';

import styles from './SbMobileDesignsDescription.scss';

type Props = {
    itemSearch: SbMobileDesignsDescription_itemSearch$data;
};

const SbMobileDesignsDescriptionComponent: FC<Props> = ({ itemSearch }) => {
    const { appliedFilters } = itemSearch;
    const creatorFilter = (appliedFilters || []).find(f => f?.name === pageTypeConstants.CREATOR);
    const creatorName = creatorFilter?.values?.[0]?.displayName;
    if (!creatorName) {
        return null;
    }
    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <FormattedMessage
                    id="sb.contentModule.SbMobileDesignsDescription.title"
                    defaultMessage="Iconic Designs from {creatorName}"
                    values={{ creatorName }}
                />
            </div>
        </div>
    );
};

export const SbMobileDesignsDescription = createFragmentContainer(
    SbMobileDesignsDescriptionComponent,
    {
        itemSearch: graphql`
            fragment SbMobileDesignsDescription_itemSearch on ItemSearchQueryConnection {
                appliedFilters {
                    name
                    values {
                        displayName
                    }
                }
            }
        `,
    }
);
