import { type FC, Fragment } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { Swiper } from 'dibs-pinch-zoom-swipe';
import { trackEvent } from 'dibs-tracking';
import { SeoLink } from 'dibs-seo/exports/SeoLink';
import { getSrcsetString } from 'dibs-image-utils/exports/srcSet';

import { SbMobileDesignsDescription } from './SbMobileDesignsDescription';
import { type SbMobileDesignTiles_itemSearch$data } from './__generated__/SbMobileDesignTiles_itemSearch.graphql';

import styles from './SbMobileDesignTiles.scss';

type TileType = NonNullable<SbMobileDesignTiles_itemSearch$data['creatorDesignTiles']>[0];

type Props = {
    itemSearch: SbMobileDesignTiles_itemSearch$data;
};

const SbMobileDesignTilesComponent: FC<Props> = ({ itemSearch }) => {
    const { creatorDesignTiles = [], displayUriRef } = itemSearch;
    if (!creatorDesignTiles || creatorDesignTiles.length < 2) {
        return null;
    }

    const tilesClassNames = {
        item: styles.item,
        list: styles.list,
        wrapper: styles.wrapper,
    };

    return (
        <Fragment>
            <SbMobileDesignsDescription itemSearch={itemSearch} />
            <Swiper
                itemsPerPage={2.5}
                classNames={tilesClassNames}
                /**
                 * Whe need to re-initialize the swiper model when the children change. We know that
                 * can happen when the `displayUriRef` changes, so we just remount the components
                 * with the key hack.
                 */
                key={displayUriRef}
            >
                {creatorDesignTiles.map((tile: TileType, index: number) => {
                    const { displayName, browsePageLinkData, imageUrl } = tile || {};
                    if (!displayName || !browsePageLinkData || !imageUrl) {
                        return null;
                    }
                    return (
                        <SeoLink
                            linkData={browsePageLinkData}
                            className={styles.tile}
                            key={displayName}
                            dataTn={`design-tile-link-${index}`}
                            onClick={() =>
                                trackEvent({
                                    category: 'promo interaction',
                                    action: 'more from iconic design creator clicked',
                                    label: 'creator page',
                                })
                            }
                        >
                            <span className={styles.imageWrapper}>
                                <img
                                    className={styles.image}
                                    src={imageUrl}
                                    alt={displayName}
                                    srcSet={getSrcsetString(imageUrl, [200, 320])}
                                    sizes="75px"
                                />
                            </span>
                            <span className={styles.title}>{displayName}</span>
                        </SeoLink>
                    );
                })}
            </Swiper>
        </Fragment>
    );
};

export const SbMobileDesignTiles = createFragmentContainer(SbMobileDesignTilesComponent, {
    itemSearch: graphql`
        fragment SbMobileDesignTiles_itemSearch on ItemSearchQueryConnection {
            ...SbMobileDesignsDescription_itemSearch
            displayUriRef
            creatorDesignTiles(count: 6) {
                displayName
                imageUrl
                browsePageLinkData {
                    ...SeoLink_linkData
                }
            }
        }
    `,
});
