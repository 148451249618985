/**
 * @generated SignedSource<<34f014b76297e5b15a358b83faeeb07c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbMobileDesignTiles_itemSearch$data = {
  readonly creatorDesignTiles: ReadonlyArray<{
    readonly browsePageLinkData: {
      readonly " $fragmentSpreads": FragmentRefs<"SeoLink_linkData">;
    };
    readonly displayName: string | null;
    readonly imageUrl: string | null;
  } | null> | null;
  readonly displayUriRef: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileDesignsDescription_itemSearch">;
  readonly " $fragmentType": "SbMobileDesignTiles_itemSearch";
};
export type SbMobileDesignTiles_itemSearch$key = {
  readonly " $data"?: SbMobileDesignTiles_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileDesignTiles_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbMobileDesignTiles_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileDesignsDescription_itemSearch"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayUriRef",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "count",
          "value": 6
        }
      ],
      "concreteType": "CreatorDesigns",
      "kind": "LinkedField",
      "name": "creatorDesignTiles",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LinkData",
          "kind": "LinkedField",
          "name": "browsePageLinkData",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SeoLink_linkData"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "creatorDesignTiles(count:6)"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "a2339b20cddb97b441bd944faf71ac51";

export default node;
